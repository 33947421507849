

.wrap-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.main-container{
    width: 100%;
    max-width: 1200px;
}

@media only screen and (max-width: 480px) {
    .main-container{
        width: 100%;
    }
}
