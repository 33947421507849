.login {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  /* padding: 11px; */
  position: relative;
  background: white;
}

.container-login {
  position: relative;
  width: 500px;
  background: white;
  padding: 20px 16px;
  border-radius: 12px;
  margin: 50px 0;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  display: flex;
  flex-flow: column;
}

.login-bg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.login-logo {
  align-self: center;
  margin-bottom: 10px;
}

.login-form {
}

.login-text__btm {
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
  align-self: center;
  margin-top: 5px;
}

.ant-form-item-label {
  align-self: flex-start;
}

.ant-form-item {
  flex-flow: column;
  /* align-items: flex-start; */
}
